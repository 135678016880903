@import "../../../assets/styles/global";

.nav_wrapper {
    width: 100%;
    background-color: #1d2126;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // position: relative;
    .top_navigation_wrapper {
        .nav_logo {
            display: flex;
            justify-content: center;
            padding-bottom: 24px;
            height: 100%;
            max-height: 136px;
        }

        .divider {
            margin-top: 24px;
            margin-bottom: 24px;
            width: 100%;
            height: 2px;
            background-color: #2b2f33;
        }

        .nav_link_wrapper {
            cursor: pointer;
            display: flex;
            padding: 15px 16px;
            align-items: center;
            margin: 0px 10px;
            text-decoration: none;

            span {
                color: #8c969b !important;
                font-family: OnestRegular;
                font-size: 16px;
                font-style: normal;
                line-height: 22px;
                margin-left: 10px;
            }

            &.active_nav_menu {
                background: #2572d9;
                border-radius: 10px;
                border-left: 4px solid #fff;
                margin-left: 6px;
                svg path {
                    fill: #fff;
                }
                span {
                    color: #fff !important;
                }
            }
            &.nav_link_exit {
                svg {
                    transform: rotate(90deg);
                    path {
                        fill: rgb(161, 171, 178);
                    }
                }
            }
        }
    }
    .action_buttons {
        button {
            padding: 19px 22px;
        }
        .btn-tertiary {
            color: #8c969b;
        }
    }
    
    .navbar_footer {
        .block_item {
            padding: 20px;
            border-top: 1px solid #32373d;
        }
        .support {
            font-family: OnestRegular;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            color: #fff;
            a {
                color: #059af7;
                text-decoration: none;
            }
        }
        .copyright {
            color: #8c969b;
            font-family: OnestRegular;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            margin-top: 10px;
            a {
                color: #059af7;
                text-decoration: none;
            }
        }
    }
}
.custom_modal_container {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    min-width: 400px;
    position: relative;
    padding-bottom: 20px;
    font-family: OnestRegular;
}

.cancel_button{
    background-color: #E23D3D !important;
    color: #fff!important;
    border: none!important;
    &:hover{
        // .size-l.btn-secondary:hove
        background-color: #b82222 !important;  
    }
  }