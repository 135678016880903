@import "./assets/styles/global.scss";

@font-face {
  font-family: "OnestRegular";
  src:
    local("OnestRegular"),
    url("./assets/fonts/onest/Onest-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "OnestMedium";
  src:
    local("OnestMedium"),
    url("./assets/fonts/onest/Onest-Medium.ttf") format("truetype");
  font-weight: 500;
}

body {
  font-family: OnestRegular;
  margin: 0;
  width: 100%;
  height: 100%;
  overflow: unset !important;
  @include custom-scroll;
}

.kyc-super-section {
  flex-direction: column;
  display: flex;
  width: 100%;
  margin: 0 auto;
  &-no-scroller {
    overflow: hidden;
  }
  @include desktop-and-above {
    min-width: 1200px;
    display: flex;
  }
}

.MuiDataGrid-cell:focus {
  outline: none !important;
}

.button-container {
  height: 48px !important;

  button {
    height: 100% !important;
  }
}

.cancel_button {
  background-color: #e23d3d !important;
  color: #fff !important;
  border: none !important;
  &:hover {
    // .size-l.btn-secondary:hove
    background-color: #b82222 !important;
  }
}

.notistack-SnackbarContainer {
  .notistack-Snackbar {
    & > div {
      background-color: #fff;
      padding: 0px 10px;
    }
    #notistack-snackbar {
      width: 100%;
      padding: 0px ;
    }
  }
}
