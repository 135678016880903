@import "../../../assets/styles/global";

.main_section {
    background-color: #f7f9fe;

    .main_grid {
        height: 100vh;
        @include grid-main;

        .w100 {
            padding: 0px 20px 20px;
            &.background_none {
                background: none !important;
                box-shadow: none !important;
            }
        }
    }
}


