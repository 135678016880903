@mixin custom-scroll {
    &::-webkit-scrollbar-track {
        border-radius: 5px;
        background-color: transparent;
    }

    &::-webkit-scrollbar {
        width: 5px;
        background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: rgba($color: #000, $alpha: 0.3);
    }
}
