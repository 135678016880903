@import "../../../../assets/styles/global.scss";

.notification_content {
    width: 450px;
    min-height: 450px;
    max-height: 600px;
    position: absolute;
    left: -210px;
    top: 50px;
    border: 1px solid #e1e6ed;
    box-shadow: 2px 1px 12px -4px #777272;
    background: #fff;
    cursor: default;
    z-index: 9999;

    .preloader_notif{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 0px ;
    }

    .notif_modal_title_container {
        display: flex;
        padding: 25px 20px;
        justify-content: space-between;
        border-bottom: 1px solid #e1e6ed;
        box-shadow: 0px 8px 5px -10px rgba(0, 0, 0, 0.3);

        .notif_modal_title {
            //styleName: Onest-18-M;
            font-family: Onest;
            font-size: 18px;
            align-items: center;
            display: flex;
        }
        .mars_as_read {
            background: #e7f1ff;
            //styleName: Onest-14-M-No LH;
            font-family: Onest;
            font-size: 14px;
            font-weight: 500;
            line-height: 18px;
            letter-spacing: 0em;
            text-align: left;
            color: #2f80ed;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 5px 10px;
            cursor: pointer;
            &.mars_as_read_is_clicked:active {
                box-shadow: inset 0px 1px 5px 0px rgba(0, 0, 0, 0.3);
                border-radius: 4px;
            }

            &.mars_as_read_disabled {
                cursor: no-drop;
                opacity: 0.5;
                color: #b7b7b7;
            }
        }
    }
    .notif_modal_content {
        overflow-y: scroll;
        height: 435px;
        margin-top: 1px;

        &.is_loading {
            justify-content: center;
            align-items: center;
            overflow: hidden;
            display: flex;
        }
        @include custom-scroll;
    }
}
