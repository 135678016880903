@import "../../assets/styles/global.scss";

.statistic_parent {
    display: flex;
    padding: 16px 16px 100px 16px;
    justify-content: center;
    border-radius: 4px;
    border: 1px solid #e1e6ed;
    background: #fafbfc;

    .new_order_container {
        width: 860px;
        display: flex;
        justify-content: space-between;

        .buy_sell_container {
            width: 500px;
            box-sizing: border-box;
        }
        .stakan {
            width: 350px;
            border-radius: 0px 4px 4px 0px;
            border: 1px solid #edf0f2;
            background: #fff;

            .statistic_bottombar {
                height: 100%;
                max-height: 533px;
                overflow-x: hidden;
                overflow-y: scroll;
                &::-webkit-scrollbar-track {
                    border-radius: 10px;
                    background-color: #f5f5f5;
                }

                &::-webkit-scrollbar {
                    width: 5px;
                    background-color: #f5f5f5;
                }

                &::-webkit-scrollbar-thumb {
                    border-radius: 10px;
                    background-color: rgba($color: #000, $alpha: 0.1);
                }
            }
        }
    }

    .tabs_items_container {
        display: flex;
        justify-content: flex-start;
        box-sizing: border-box;
        width: 100%;

        .tabs_item {
            display: flex;
            padding: 9px 26px;
            justify-content: center;
            align-items: center;
            border-radius: 0px;
            border: 1px solid #e1e6ed;
            background: #fafbfc;
            flex: 1;
            color: #8c969b;
            font-family: OnestRegular;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            cursor: pointer;
            margin-right: -1px;

            &:first-child {
                border-radius: 4px 0px 0px 0px;
            }
            &:last-child {
                border-radius: 0px 4px 0px 0px;
            }
            &.active {
                background: #2f80ed;
                color: #f4f5f7;
                border: 1px solid #2f80ed;
            }
        }
    }
    .tabs_content {
        width: 100%;
        box-sizing: border-box;
        margin-top: -1px;

        .tab_content_item_padding {
            padding: 28px;
        }
        .tab_content_item {
            border: 1px solid #e1e6ed;
            &.active_content {
                display: flex;
                flex-direction: column;
                width: 100%;
                box-sizing: border-box;
            }

            .sell_count_container {
                margin-bottom: 20px;
                .count_label {
                    color: #1d2126;
                    font-family: OnestRegular;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    margin-bottom: 5px;
                }
            }
        }
    }
    .summ_styles {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        background-color: #fff;
        width: 100%;
        border-radius: 4px;

        input {
            font-family: OnestRegular;

            color: #1d2126;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
        & > div:hover > fieldset {
            border: 1px solid #e1e6ed;
        }
    }
}
.additional_info {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 10px;

    .additional_info_label {
        color: var(--colors-content-dop, #8c969b);
        font-family: OnestRegular;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px; /* 137.5% */
    }
    .additional_info_text {
        display: flex;
        align-items: center;

        b {
            color: var(--colors-content-dop, #8c969b);
            text-align: right;
            /* Onest-18-M */
            font-family: OnestRegular;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 25.2px; /* 140% */
            margin-right: 5px;
        }
        span {
            color: #8c969b;
            font-family: OnestRegular;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }

        ol {
            padding-left: 20px;
        }
    }
    .additional_info_big_text {
        b {
            color: #23272e;
            font-family: OnestRegular;
            font-size: 24px;
            font-weight: 600;
        }
        span {
            font-family: OnestRegular;
            font-size: 18px;
            font-weight: 600;
        }
    }
}
.add_order_action_buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    & > div {
        display: flex;
        flex: 1;
        button {
            flex: 1;
        }
        &:first-child {
            margin-right: 5px !important;
        }
    }
    button {
        justify-content: space-between;
        display: flex;
        padding: 8px 12px 8px 18px;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        font-family: OnestRegular;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        width: 100%;
    }
    .blue_btn {
        color: #fff !important;
        background-color: #2f80ed !important;
        &.is_disabled {
            background-color: #8c969b !important;
        }
    }
}
.add_order_volume_title {
    color: #1d2126;
    text-align: center;
    font-family: OnestRegular;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 25.2px; /* 140% */

    background: #f7f9fe;
    display: flex;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
}
.is_header {
    color: #8c969b;
    text-align: center;
    /* Onest-14-M-No LH */
    font-family: OnestRegular;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-right: 3px;
    & > div {
        display: flex;
        flex: 1;
        flex-direction: column;
        border: 1px solid #edf0f2;
    }
}
.statistic_line {
    display: flex;
    justify-content: space-between;
    margin-bottom: -1px;

    & > div {
        min-height: 40px;
        flex: 1;
        padding: 5px;
        border: 1px solid #edf0f2;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        b {
            position: absolute;
            z-index: 999;
        }
    }
    .statistic_exchange {
        background-color: #f4f5f7;
        font-family: OnestRegular;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
    .statistic_buy {
        color: #24a551;
    }
    .statistic_sell {
        color: #e23d3d;
    }
    .gradient_box {
        position: absolute;
        left: 0px;
        top: 0px;
    }
}

.content_centered {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 530px !important;
}

.approve_order_container {
    width: 766px;
    height: 468px;
    border-radius: 4px;
    border: 1px solid #edf0f2;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background: #fff;
    margin-top: 64px;
    h2 {
        color: #1d2126;
        font-family: OnestRegular;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 33.6px; /* 140% */
    }
    .approve_order_content {
        color: #969fae;
        text-align: center;
        font-family: OnestRegular;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22.4px; /* 140% */
    }
    .add_order_action_buttons {
        flex-direction: column;
        width: 200px;
        & > div {
            margin-right: 0px !important;
            margin-bottom: 5px;
        }
    }
}
.commision_content {
    min-height: 70px;
    &.is_loading {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.disabled {
    pointer-events: none;
}