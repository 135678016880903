.logo-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    top: 10%;
}