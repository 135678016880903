@import "../../../assets/styles/global";
.warning_wrapper {
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  background: #ffb800;

  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.04),
    0px 3px 9.8px 0px rgba(0, 0, 0, 0.08);

  .star_proccess {
    border: none !important;
  }
  label {
    display: flex;
    align-items: center;
    svg {
      margin-right: 10px;
    }
  }
}

.background_gray {
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  background: #a1abb2;

  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.04),
    0px 3px 9.8px 0px rgba(0, 0, 0, 0.08);

  .star_proccess {
    border: none !important;
  }
  label {
    display: flex;
    align-items: center;
    color: white;
    svg {
      margin-right: 10px;
    }
  }
}
