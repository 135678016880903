@import "../../../assets/styles/global";

.setting_content_wrapper {
    border-bottom: 0.5px solid #e1e6ed;
    .setting_info_text {
        font-family: OnestMedium;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 22.4px;
        margin-bottom: 24px;
    }
}

@import "../../../assets/styles/global";

.settings_drawer_wrapper {
    padding: 30px;
    width: 300px;
    display: flex;
    flex-direction: column;
    height: 100%;

    .drawer_title_container {
        display: flex;
        justify-content: space-between;
        .drawer_title {
            color: #000;
            font-family: OnestRegular;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: 33.6px;
            margin-bottom: 40px;
        }
        .drawer_close_icon {
            margin-top: -15px;
            height: 55px;
            svg {
                width: 40px;
                height: 40px;
            }
        }
    }
    .drawer_content {
        flex-grow: 1;
        flex-direction: column;
        display: flex;
    }
    .buttons {
        button {
            width: 100%;
            background: #2f80ed;
            color: #fff;
            font-family: OnestRegular;
            font-size: 16px;
            font-weight: 500;
            text-transform: capitalize;
            &.no_margin {
                span {
                    margin-left: 5px;
                }
                svg {
                    margin-left: 0px;
                    color: #fff;
                }
            }
            &.saved {
                background-color: #20b053;
            }
            svg {
                margin-left: 5px;
                path {
                    fill: #ebf0f7;
                }
            }
        }
    }
}
.breadcrumbs {
    font-family: OnestRegular;
    p {
        font-family: OnestRegular;
    }
}

.top_nav_bar_wrapper {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    cursor: pointer;

    .top_bar_page_name {
        font-family: OnestRegular;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 33.6px;
    }

    .top_bar_page_user_wrapper {
        display: flex;
        align-items: center;
        span {
            font-family: OnestMedium;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 22.4px;
            margin-right: 8px;
        }
        .settings_icon_wrapper {
            margin-left: 12px;
            margin-right: 12px;
            cursor: pointer;
        }
        .user_name_info_wrapper {
            display: flex;
            align-items: center;
        }
    }
}

.select_lang_container_parent {
    width: fit-content;
    top: 0px;
    right: 0px;
    .select_lang_container {
        position: relative;
        background-color: #fafbfc;
        & > svg {
            position: absolute;
            top: 10px;
            left: 4px;
        }
        & > div {
            outline: none !important;
            svg {
                fill: #5b636d;
            }
            & > div {
                padding: 10px 30px 10px 30px;
                border: 2px solid #e1e6ed;
                color: #1d2126;
                font-weight: 500;
            }
        }
    }
}
.notification_icon_wrapper{
    position: relative;
    padding: 7px 7px 2px 7px;
}
.notification_selected{
    background: #E7F1FF;
    & > svg path{
        fill: #2F80ED !important;
    }
}