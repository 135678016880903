@import "../../assets/styles/global";

.mainLoginSection {
    width: 100%;
    height: 100%;
    background-color: white;

    .grid_wrapper {
        display: grid;
        grid-template-columns: 1fr 0.8fr;
        grid-template-rows: auto;
        .bg_image_container {
            // width: 50%;
            height: 100%;
            background-image: url(../../assets/media//images/signin-bg-image.png);
            background-position: 50% center;
            background-repeat: no-repeat;
            background-size: cover;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .signin_container {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 100vh;

            .mt_16 {
                margin-top: 16px;
            }

            .mb_24 {
                margin-bottom: 24px;
            }
            .forget_password {
                display: flex;
                justify-content: flex-end;
                color: #2F80ED;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                margin-bottom: 15px;
                width: 100%;
                cursor: pointer;
            }
            .login_button {
                margin-top: 10px;
            }

            .main_container {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;
                max-width: 418px;

                .forgotPasswordWrapper {
                    width: 100%;
                    display: flex;
                    flex-direction: column;

                    
                }
            }
            .copyright {
                color: #8c969b;
                text-align: center;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
                position: absolute;
                bottom: 10px;
            }
        }
        .error_container {
            margin-top: 10px;
            font-weight: 700;
            color: #db4c4c;
        }
    }
}
.select_lang_container_parent {
    width: fit-content;
    position: absolute;
    top: 0px;
    right: 0px;
    margin: 15px;
    .select_lang_container {
        position: relative;
        background-color: #fafbfc;
        & > svg {
            position: absolute;
            top: 10px;
            left: 4px;
        }
        & > div {
            outline: none !important;
            svg {
                fill: #5b636d;
            }
            & > div {
                padding: 10px 30px 10px 30px;
                border: 2px solid #e1e6ed;
                color: #1d2126;
                font-weight: 500;
            }
        }
    }
}

.otp-container {
    display: flex;
    flex-direction: column;

    .justify-align-center {
        display: flex;

        button {
            width: 40px;
            height: 40px;
        }
    }

    & > div {
        width: fit-content;
        margin: 0 0px 10px 0px;
    }

    .text-input-otp {
        width: 55px;
        height: 55px;
    
        @include mobile {
            width: 45px;
            height: 45px;
        }
    
        input {
            text-align: center;
            font-size: 16px;
            padding: 15px 5px;
            outline: none;
            font-weight: 600;
            font-family: OnestRegular;
            background-color: #fafbfc !important;
            color: #202020;
    
            @include mobile {
                padding: 10px 5px;
            }
    
            &:focus::placeholder {
                color: transparent;
            }
        }
    }
}

.short_auth{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}