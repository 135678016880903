@import "../../../assets/styles/global";

.button_wrapper {
    width: 100%;
    max-width: 418px;
}

.table_container{
    display: flex;
    justify-content: center;
    align-items: center;
    &.is_loading{
        min-height: 300px;
    }
}