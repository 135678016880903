@import "../../../assets/styles/global";

.button_wrapper {
    width: 100%;
    max-width: 460px;
    button {
        width: 100% !important;
        line-height: 40px;
    }
}
