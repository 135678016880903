@import "../../../../../assets/styles/global.scss";

.notification_item {
    display: flex;
    justify-content: flex-start;
    background-color: #fff !important;
    &.notif_modal_styles {
        border-bottom: 1px solid #e1e6ed;
    }
    &.notif_cursor_pointer {
        cursor: pointer;
    }

    width: 100%;
    .notif_is_read {
        width: 8px;
        height: 8px;
        background: #059af7;
        position: absolute;
        right: 0px;
        top: 6px;
        border-radius: 100%;
    }
    .notif_icon {
        padding: 10px;
        &.notif_centered_icon {
            display: flex;
            align-items: center;
            padding-left: 0px;
        }
    }

    .notif_content {
        padding: 10px 10px 10px 0px;
        width: inherit;

        .notif_title {
            font-family: OnestRegular;
            font-size: 16px;
            font-weight: 500;
            line-height: 22px;
            position: relative;
            color: #1d2126;
        }
        .notif_description {
            font-family: OnestRegular;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            color: #5b636d;
            margin: 5px 0px;
            &.no_mb {
                margin-bottom: 0px;
            }
        }
        .notif_time {
            //styleName: Onest-12-R;
            font-family: Onest;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            color: #8c969b;
        }
    }
}
