@import "../../../assets/styles/global";

.settings_drawer_wrapper {
    width: 500px;
    display: flex;
    flex-direction: column;
    height: 100%;

    .drawer_title_container {
        display: flex;
        justify-content: space-between;
        padding: 30px 30px 0px 30px;
        box-shadow: 0 5px 7px 1px rgba(0,0,0,.15);
        .drawer_title {
            color: #000;
            font-family: OnestRegular;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: 33.6px;
        }
        .drawer_close_icon {
            margin-top: -15px;
            height: 55px;
            svg {
                width: 40px;
                height: 40px;
            }
        }
    }
    .drawer_content {
        flex-grow: 1;
        flex-direction: column;
        display: flex;
        padding: 30px 30px 0px 30px;
        overflow-y: scroll;
        overflow-x: hidden;
        &.no_padding {
            padding: 0px !important;
        }

        &::-webkit-scrollbar-track {
            border-radius: 10px;
            background-color: #f5f5f5;
          }
      
          &::-webkit-scrollbar {
            width: 5px;
            background-color: #f5f5f5;
          }
      
          &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background-color: rgba($color: #000, $alpha: 0.1);
          }
    }
    .buttons {
        padding: 30px;
        box-shadow: 0 -2px 7px 1px rgba(0,0,0,.15);
        button {
            width: 100%;
            background: #2f80ed;
            color: #fff;
            font-family: OnestRegular;
            font-size: 16px;
            font-weight: 500;
            text-transform: capitalize;
            &.no_margin {
                span {
                    margin-left: 5px;
                }
                svg {
                    margin-left: 0px;
                    color: #fff;
                }
            }
            &.saved {
                background-color: #20b053;
            }
            svg {
                margin-left: 5px;
                path {
                    fill: #ebf0f7;
                }
            }
        }
    }
}
