@import "../../../assets/styles/global";

.selected_item {
    border-radius: 4px;
    border: 1.5px solid var(--colors-content-grey-border, #e1e6ed);
    background: var(--colors-dom-white, #fff);
    display: flex;
    padding: 11px 16px 11px 22px;
    /* flex-direction: column; */
    justify-content: center;
    align-items: flex-start;
    cursor: pointer;
    position: relative;

    label {
        flex: 1 0 0;
        color: #1d2126;
        font-family: OnestRegular;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        cursor: pointer;
    }
    .count_information {
        display: flex;
        align-items: center;
        svg path {
            fill: #8c969b;
        }
        span {
            color: #8c969b;
            font-family: OnestRegular;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-right: 5px;
        }
    }
}

.dropdown_focused {
    border: 2px solid rgb(5, 154, 247);
    border-radius: 4px;
    &.dropdown_disabled {
        border: 2px solid rgba(5, 154, 247, 0);
    }
    .selected_item {
        padding: 9px 14px 9px 20px !important;
    }
}
.dropdown_disabled {
    cursor: no-drop !important;
    > div, label {
        background: #e3e3e3 !important;
        cursor: no-drop !important;
    }
}

.count_options {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    margin-top: 45px;
    z-index: 99;
    box-shadow: 0px 4px 10px 0px rgba(000, 000, 000, 0.2);

    .option_container {
        margin-bottom: -1px;
        border: 1.5px solid var(--colors-content-grey-border, #e1e6ed);
        background: var(--colors-dom-white, #fff);
        display: flex;
        padding: 11px 16px 11px 22px;
        /* flex-direction: column; */
        justify-content: center;
        align-items: flex-start;
        cursor: pointer;
    }
    .selected_count {
        label,
        span {
            color: #2f80ed;
            font-weight: 600;
        }
        svg path {
            fill: #2f80ed;
        }
    }
}
