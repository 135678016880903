@import "../../../assets/styles/global";

.heading_wrapper {
    width: 100%;
    max-width: 460px;
    text-align: center;
    .headingFirst {
        color: #1D2126;
        font-family: OnestMedium;
        font-size: 32px;
        font-style: normal;
        line-height: 44.8px;
    }

    .textRegular {
        color: #5B636D;
        font-family: OnestRegular;
        font-size: 16px;
        font-style: normal;
        line-height: 25.6px;
    }
}
