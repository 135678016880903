@import "../../../assets/styles/global";

.statistic_container {
    background-color: #fff;
    min-width: 276px;
    height: 278px;
    border: 1px solid #e1e6ed;
    margin-right: 8px;
    border-radius: 4px;
    border: 1px solid #E1E6ED; 
    
    .statistic_title {
        padding: 16px 30px 8px 30px;
        align-items: flex-start; 
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22.4px;
        margin-bottom: 10px;
        background-color: #F4F5F7;
    }

    .statistic_info_wrapper {
        padding-left: 20px;
        padding-right: 20px;
        padding-right: 20px;
        display: flex;
        align-items: baseline;

        .statistic_info_label {
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 25.2px;
            color: #8C969B;
        }

        .statistic_amount {
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 25.2px; /* 140% */ 
            span {
                font-size: 18px;
                margin-left: 5px;
            }
        }
    }
}
