.tooltip-wrapper {
    position: relative;
    button {
        padding: 0px;
        margin-left: 5px;
    }
    .tooltip-title {
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 10px;
    }
    .tooltip-content {
        position: absolute;
        width: 320px;
        border-radius: 4px;
        border: 1px solid #e1e6ed;
        background: #f4f5f7;
        padding: 10px;
        box-sizing: border-box;
        color: #5b636d;
        font-size: 14px;
        z-index: 9999;
        left: 35px;
        margin-top: -27px;
        box-shadow: 2px 2px 19px -2px rgba(000, 035, 039, 0.4);
        &.mt0 {
            margin-top: -47px !important;

           
        }
        &.left-position {
            right: 35px;
            left: unset !important;

            .triangle svg {
                right: -8px !important;
                left: unset !important;
                transform: rotate(180deg) !important;
            }
        }
    }

    .triangle svg {
        position: absolute;
        left: -8px;
        top: 50px;
        path{
            fill: #f4f5f7;
        }
    }
}
