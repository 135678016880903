@import "../../assets/styles/global.scss";
.licenses_container {
    display: flex;
    flex-direction: column;
    & > div {
        margin-bottom: 20px;
        & > div {
            width: 100%;
        }
    }

    .textarea {
        min-height: 200px;
    }
}
.filter_container {
    display: flex;
    margin-bottom: 10px;
}
.order_status {
    justify-content: center !important;
}

.order_status_error {
    color: red;
}

.header_action_buttons > div > div{
    align-items: center;
    justify-content: center;
}
.filter_button {
    display: flex;
    padding: 9px 16px;
    justify-content: center;
    align-items: center;
    color: #8c969b;
    font-family: OnestRegular;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    background-color: #fafbfc;
    border: 1px solid #e1e6ed;
    text-transform: capitalize;
    color: #8c969b;
    cursor: pointer;
    border-radius: 4px;
    height: 40px;
    margin-right: 8px;
}
.filter_button_active {
    background-color: #2f80ed;
    color: #fff;
}
.customer_page_bottom_container {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    button {
        line-height: 30px;
    }
}
.calendar_and_filters_container {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.select_period {
    display: flex !important;
    flex-direction: row !important;
    align-items: center;
    font-family: OnestRegular;
    label {
        color: #5b636d;
    }
    div {
        font-family: OnestRegular;
        color: #5b636d;
    }
    & > svg {
        margin-right: 10px;
    }
    fieldset {
        border: none;
    }
}
.add_license_btn {
    background-color: #e7f1ff !important;
    color: #2f80ed !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: normal !important;
    text-transform: unset !important;
    padding: 7px 8px !important;
}
.licenses_page_bottom_container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}
.export_csv {
    color: #2f80ed;
    font-size: 16px;
    font-family: OnestRegular;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: none;
    svg {
        margin-left: 15px;
    }
}

.action_icons{
    display: none;
}

div[role=row]:hover{
    .action_icons{
        display: block;
    }
}

.custom_modal_container {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    min-width: 500px;
    position: relative;
    padding: 30px;

    .close_icon {
        position: absolute;
        right: 0px;
        top: 0px;
        margin: 20px;
        cursor: pointer;
    }
    .cancel_button{
        background-color: #E23D3D !important;
        color: #fff!important;
        border: none!important;
        &:hover{
            // .size-l.btn-secondary:hove
            background-color: #b82222 !important;  
        }
    }
}
.action_buttons {
    svg {
        cursor: pointer;
        rect {
            fill: none;
        }
    }
}
.action_buttons {
    // &:hover
    &> div svg{
       opacity: 0.2;
    }
    &> div:hover svg{
        opacity: 1;
    }
    &> div:hover .pencil_icon:hover path{
        fill:#008a60;
    }
    &> div:hover .trash_icon:hover path{
        fill:#af0505;
    }
}

.blue_btn {
    display: flex;
    padding: 8px 12px 8px 18px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background: #2F80ED;
    font-family: OnestMedium;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal; 
    color: #ffffff;
    svg {
        width: 24px !important;
        height: 24px !important;
        path {
            fill: #ffffff;
        }
    }
    &.is_disabled {
        background-color: #8c969b !important;
    }
}
.table_header {
    background-color: #ebf0f7;
    > div > div > div > div {
        color: #5b636d;
        font-weight: 600;
    }
}