@import "../../assets/styles/global";
.statistic_parent {
    display: flex;
    min-height: 795px;
    padding: 16px 16px 0px 16px;
    align-items: flex-start;
    border-radius: 4px;
    border: 1px solid #E1E6ED;
    background: #FAFBFC; 
    
    .new_invoice_cta_wrapper {
        display: flex;
        min-width: 276px;
        height: 278px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
        border-radius: 4px;
        border: 1px solid #E1E6ED;
        background: #F4F5F7;
        cursor: pointer;
        color:  #8C969B;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 22.4px;
    }
}
.settings_drawer_wrapper {
    padding: 30px 30px 28px 30px;
    width: 386px;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;

    .drawer_title_container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;

        .drawer_title {
            color: #000;
            font-family: OnestRegular;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: 33.6px;
        }
        .drawer_close_icon {
            // margin-top: -10px;
            height: 55px;
            svg {
                width: 40px;
                height: 40px;
            }
        }
    }
    .drawer_btn {
        width: 100%;
        padding: 12px 14px 12px 22px;
        justify-content: center;
        align-items: center; 
        border-radius: 4px;
        background: #2F80ED;
        color: #ffffff;
    }
}
